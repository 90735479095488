* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Press Start 2P", cursive;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #000;
  width: 100%;
  height: 100%;
  color: #fff;
}

.canvas-wrapper {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

.landing {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 300;
}

.full-container {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 300;
}

.resume-info,
.experience-info {
  margin: 3rem;
  width: 50%;
  text-align: left;
  line-height: 1.5rem;
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
  transition: all 600ms ease-out;
  opacity: 0;
  background-color: #000;

  p,
  .date,
  a,
  .feature-list li {
    font-family: "Roboto Slab";
  }

  .date,
  .job-title,
  .description,
  .feature-list li {
    color: lightgray;
  }

  .date {
    font-size: small;
    vertical-align: top;
  }

  .experience-image {
    margin-top: 0.5rem;
  }

  .feature-list li {
    list-style: circle;
  }

  a {
    color: #fff;
  }
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

.display-none {
  display: none;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

.resume-link a {
  font-family: "Roboto Slab";
  color: #999;
}

.sub-container {
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  margin: 2rem 0;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    margin-top: 1rem;
  }
}

.scroll {
  animation-direction: alternate-reverse;
  animation: blink 2s infinite;
}

.sticky {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.stars-top,
.stars-mid,
.stars-bot {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-size: cover;
  z-index: 0;
}

.stars-top {
  background-image: url("../images/stars-top.png");
}
.stars-mid {
  background-image: url("../images/stars-mid.png");
}
.stars-bot {
  background-image: url("../images/stars-bot.png");
}

@media only screen and (max-width: 400px) {
  canvas {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .resume-info,
  .experience-info {
    width: 90%;
  }
}
